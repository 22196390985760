@use 'fonts';

@use 'variables' as v;
@use 'spysass/components/reset';
@use 'spysass/components/base' with (
  $base-color: v.$base-color,
  $base-font-size: v.$base-font-size,
  $body-font-family: v.$body-font-family
);
@use 'spysass/components/type' with (
  $heading-font-family: v.$heading-font-family,
  $heading-font-color: v.$heading-font-color,
  $heading-font-weight: v.$heading-font-weight
);

@use 'spysass/themes/form/foundation' with (
  $input-padding: v.$input-padding
);

@use 'spysass/themes/form/validation/linus' with (
  $form-invalid-color: v.$form-invalid-color,
  $form-valid-color: v.$form-valid-color,
  $form-invalid-icon: url('../img/form-invalid.svg'),
  $form-valid-icon: url('../img/form-valid.svg')
);
@use 'spysass/themes/form/labels/linus' as labels;

@use 'styles';
@use 'layout';
@use 'content/start';
@use 'content/medarbetare';
@use 'content/nyhetsbrev';
@use 'content/kontakt';
