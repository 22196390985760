@use 'sass:math';
@use '../breakpoints';
@use '../variables' as v;

/* PAGE:START */

.page.start {
  > section:not(:last-child) {
    padding-bottom: 16px;
  }

  > .content {
    > div.left {
      flex-grow: 2;
    }
  }

  .services {
    @media #{breakpoints.$medium-up} {
      display: flex;
      flex-wrap: wrap;

      > section:nth-of-type(odd) {
        margin-right: 32px;
      }
    }

    > h1 {
      width: 100%;
      margin-bottom: 16px;
    }

    > section {
      background: no-repeat;
      padding: 0 0 32px 65px;
      flex: 1 0 calc(50% - 16px);
      overflow: hidden;

      &:last-child {
        padding-bottom: 0;
      }

      @media #{breakpoints.$medium-up} {
        &:nth-last-child(2) {
          padding-bottom: 0;
        }
      }

      > p {
        font-size: rem(13px);
        &:last-child {
          margin: 0;
        }
      }

      > h1 {
        font-size: rem(18px);
        margin: 0 0 2px 0;
      }

      &.bokforing {
        background-image: url('../../img/icon-calculator.svg');
      }

      &.fakturering {
        background-image: url('../../img/icon-paperplane.svg');
      }

      &.loneadministration {
        background-image: url('../../img/icon-safe.svg');
      }

      &.bokslut {
        background-image: url('../../img/icon-magic.svg');
      }

      &.arsredovisning {
        background-image: url('../../img/icon-note.svg');
      }

      &.deklaration {
        background-image: url('../../img/icon-check.svg');
      }

      &.chef {
        background-image: url('../../img/icon-people.svg');
      }

      &.radgivning {
        background-image: url('../../img/icon-speech.svg');
      }
    }
  }
}

@media #{breakpoints.$small-only} {
  body.start {
    .services {
      ul {
        > li {
          float: none;
          width: 100%;
        }
      }
    }
  }
}
