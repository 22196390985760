/* PAGE: KONTAKT */

.kontakt.page {
  form {
    &.sending {
      cursor: wait !important;

      input,
      button,
      textarea {
        cursor: wait !important;
      }
    }
  }
}
