/* LAYOUT & DESIGN*/

@use 'sass:math';
@use './breakpoints';
@use './variables' as v;
@use 'spysass/mixins/flex';
@use 'spysass/mixins/utils';

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

$nav-transition-delay: 0.1s;
$nav-transition-time: 0.3s;
$mobile-nav-transition-time: 0.3s;
$transition-time: 0.5s;
$header-height: 80px;
$header-height-mobile: 50px;

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media #{breakpoints.$small-only} {
    padding-top: $header-height-mobile + 26px;
  }

  @media #{breakpoints.$medium-up} {
    padding-top: $header-height + 40px;
  }
}

header.main {
  background: black;
  width: 100%;
  position: fixed;
  z-index: 9990;

  top: 0;

  @media #{breakpoints.$small-only} {
    height: $header-height-mobile;
  }

  @media #{breakpoints.$medium-up} {
    height: $header-height;
  }

  > div {
    @include flex.container;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: v.$content-max-width;
    margin: 0 auto;
    padding: 0 math.div(v.$global-gutter, 2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1.logo {
      margin: 0;
      padding: 0;
      line-height: 1;

      > a {
        display: block;
        background: url('../img/millerkonsult-logo-white.svg') 0px 0px / 100% auto no-repeat;
        width: 200px;

        @media #{breakpoints.$small-only} {
          width: 150px;
        }

        @include utils.ratio(v.$logo-ratio);
        > span {
          display: none;
        }
      }
    }

    > nav {
      > ul {
        @include utils.wipe-list;
      }

      > div.cover {
        display: none;
      }
    }

    @media #{breakpoints.$small-only} {
      > nav {
        height: 25px;
        > button {
          @include utils.wipe-button;
          background: url('../img/nav-icon.svg') 0px 0px / 100% auto no-repeat;
          width: 34px;
          height: 25px;
          cursor: pointer;
        }

        > ul {
          // display: none;
          position: fixed;
          left: 100%;
          z-index: 9999;
          width: 100%;
          top: $header-height-mobile;
          display: block;
          height: calc(100vh - #{$header-height});
          transition: left $mobile-nav-transition-time;

          > li {
            display: block;
            border-bottom: 1px solid black;

            > a {
              display: block;
              background: white;
              font-size: 1.2em;
              width: 100%;
              text-align: center;
              padding: 20px 0;
            }

            &.current {
              > a {
                color: #ccc;
              }
            }
          }
        }

        > div.cover {
          display: block;
          transition: opacity $mobile-nav-transition-time;
          opacity: 0;
          background: black;
          position: fixed;
          top: $header-height-mobile;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9998;
          pointer-events: none;
        }

        &.visible {
          > ul {
            left: 0;
          }

          > div.cover {
            opacity: 0.5;
            pointer-event: initial;
          }
        }
      }
    }

    @media #{breakpoints.$medium-up} {
      > nav {
        font-family: v.$heading-font-family;
        font-weight: 500;
        font-size: 1em;
        align-self: flex-end;

        > button {
          display: none;
        }

        > ul {
          padding: 0;
          margin: 0 -5px;
          list-style: none;

          > li {
            float: left;
            padding: 0 10px;
            margin: 0 -5px;
            overflow: hidden;

            > a {
              display: block;
              position: relative;
              height: 35px;
              perspective: 200px;

              > span {
                height: 30px;
                padding: 8px 15px 0 15px;
                position: relative;
                top: 5px;
                z-index: 100;
                color: #fff;
                text-decoration: none;
                line-height: 1;
                display: block;
                background-color: #666;
                transform: rotateX(0deg);
                transform-origin: center bottom;
                transition: transform $nav-transition-time $nav-transition-delay;
              }

              &:before {
                content: attr(data-title);
                height: 35px;
                position: absolute;
                bottom: -1px;
                left: 0;
                right: 0;
                color: black;
                display: block;
                z-index: 9998;
                padding: 8px 15px 0 15px;
                background: white;
                transform: rotateX(-90deg);
                transform-origin: center bottom;
                transition: transform $nav-transition-time;
              }
            }

            &.current,
            &:hover {
              > a {
                > span {
                  transform: rotateX(90deg);
                  transition: transform $nav-transition-time;
                }

                &:before {
                  transform: rotateX(0deg);
                  transition: transform $nav-transition-time $nav-transition-delay;
                }
              }
            }
          }

          &:hover > li.current:not(:hover) > a {
            > span {
              transform: rotateX(0deg);
              transition: transform $nav-transition-time $nav-transition-delay;
            }

            &:before {
              transform: rotateX(-90deg);
              transition: transform $nav-transition-time;
            }
          }
        }
      }
    }
  }
}

main {
  flex: 1 0 auto;
  overflow: hidden;
  width: 100%;
  max-width: v.$content-max-width;
  margin: 0 auto;
  perspective: 5000px;
  perspective-origin: center 500px;

  @media #{breakpoints.$small-only} {
    transition: transform $mobile-nav-transition-time;

    body.shift & {
      transform: translateX(-30%);
    }
  }

  > .page {
    background: white;
    width: 100%;
    padding: 0 math.div(v.$global-gutter, 2);
    transform-origin: center 0px;
    backface-visibility: hidden;

    &.animate {
      float: left;
      margin-right: -100%;
      position: relative;
      overflow: hidden;
      transform: rotateY(0deg);

      &.active {
        transition: transform $transition-time;
      }

      &.enter {
        transform: rotateY(-180deg);

        &.back {
          transform: rotateY(180deg);
        }

        &.active {
          transform: rotateY(0deg);
        }
      }

      &.leave {
        transform: rotateY(0deg);

        &.active {
          transform: rotateY(180deg);

          &.back {
            transform: rotateY(-180deg);
          }
        }
      }
    }

    > header {
      display: none;
    }

    > .content {
      width: 100%;
      max-width: v.$content-max-width;
      margin: 0 auto;
    }
  }
}

/* LAYOUT:FOOTER */
footer.main {
  width: 100%;
  flex: 0 0 auto;

  &.animate {
    position: fixed;
    transition: top $transition-time;
  }

  .content {
    width: 100%;
    max-width: v.$content-max-width;
    margin: 0 auto;
  }

  > .top {
    padding: 0 math.div(v.$global-gutter, 2);
    margin-top: 100px;
    width: 100%;
    background: #dfdfdf;
    > .content {
      padding: math.div(v.$global-gutter, 2) 0;
      position: relative;
      @include utils.clear;

      > [property='name'] {
        position: absolute;
        right: 0;
        @include utils.ratio(v.$logo-ratio);
        background: url('../img/millerkonsult-logo-grey.svg') 0px 0px / 100% auto no-repeat;

        > span {
          display: none;
        }

        @media #{breakpoints.$small-only} {
          width: 300px;
          bottom: calc(100% - 2px);
        }

        @media #{breakpoints.$medium-up} {
          width: 500px;
          bottom: calc(100% - 5px);
        }

        ~ * {
          background-repeat: no-repeat;
          color: #666;
          margin: 0;
          line-height: 1.4;
          min-height: 30px;

          &[property='telephone'] {
            background-image: url('../img/icon-phone.svg');
          }

          &[property='email'] {
            background-image: url('../img/icon-email.svg');
          }

          &.address {
            background-image: url('../img/icon-envelope.svg');

            > p {
              margin: 0;
            }
          }

          @media #{breakpoints.$small-only} {
            padding: 0px 0px 16px 30px;
            background-position: 5px 0px;

            &.address {
              background-position: 5px 2px;
            }
          }

          @media #{breakpoints.$medium-up} {
            padding-left: 60px;
            float: right;
            background-position: 35px 0px;

            &.address {
              background-position: 35px 2px;
            }
          }
        }
      }
    }
  }

  > .bottom {
    width: 100%;
    background: #000;
    color: white;
    padding: 0 math.div(v.$global-gutter, 2);

    > .content {
      padding: math.div(v.$global-gutter, 2) 0;

      @media #{breakpoints.$small-only} {
        p:last-child {
          margin: 0;
        }
      }

      @media #{breakpoints.$medium-up} {
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }
    }
  }
}
