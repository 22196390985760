@use './breakpoints';
@use 'spysass/mixins/utils';
@use 'spysass/mixins/buttons';
@use 'spysass/mixins/flex';
@use './variables' as v;

a {
  color: v.$base-color;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: v.$color-1;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-child {
    margin-top: 0;
  }
}

button {
  @include utils.wipe-button;
  @include buttons.size($font-size: 16px, $padding: 15px 20px);
  background: v.$base-color;
  color: white;
  cursor: pointer;
  &:disabled {
    background: #ccc;
    cursor: default;
  }
}

@media #{breakpoints.$medium-up} {
  .row {
    display: flex;

    > * {
      @include flex.simple-cell;
    }
  }
}
