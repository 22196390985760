// USER VARIABLES
//

@use 'sass:math';

//
// Base Variables
//

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with brower-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it effect the grid breakpoints,
// set @em-base to @base-font-size and make sure @base-font-size is a px value.

// @base-line-height is 24px while @base-font-size is 16px
// @base-line-height: 150%;

// We use these to control various global styles
// @body-bg: #fff;
// @body-font-color: #222;
$body-font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
$base-color: #0066ff;
$color-1: #ffae00;

//
// Typography Variables
//

// @include-html-type-classes: @include-html-classes;

// We use these to control header font styles
$heading-font-family: Helvetica, Arial, sans-serif;
$heading-font-weight: 500;
// @header-font-style: normal;
$heading-font-color: #444;
// @header-line-height: 1.4;
// @header-top-margin: .2em;
// @header-bottom-margin: .5em;
// @header-text-rendering: optimizeLegibility;

$form-valid-color: $base-color;
$form-invalid-color: #ffae00;
$base-font-size: 14px;
$input-padding: 14px 12px;
$content-max-width: 900px;
$global-gutter: 30px;

$logo-ratio: math.div(326.515, 51.844);
